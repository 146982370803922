import Buttons from "./Buttons";
import PropTypes from "prop-types";

const Footer = ({ className = "" }) => {
  return (
    <section
      className={`bg-neutral-0 box-border flex flex-row items-start justify-start pt-[62px] px-[260px] pb-17 gap-[200px] max-w-full text-left text-base text-texticon-primary-black font-button-200 border-t-[1px] border-solid border-neutral-100 mq750:gap-[32px] mq750:pl-[59px] mq750:pr-[59px] mq750:box-border mq450:gap-[16px] mq450:pl-5 mq450:pr-5 mq450:box-border mq1440:flex-wrap ${className}`}
    >
      <img
        className="h-[51px] w-[190px] relative object-contain"
        loading="lazy"
        alt=""
        src="/transparent-logo-1@2x.png"
      />
      <div className="w-[203px] relative tracking-[-0.03px] leading-[24px] inline-block shrink-0">
        Burp Groceries helps people choose which groceries work best for their
        condition using smart, AI-based crowdsourcing.
      </div>
      <div className="h-[120px] w-px relative bg-neutral-100 mq1225:w-full mq1440:h-px" />
      <div className="w-[457px] flex flex-row items-center justify-between py-0 pr-[10px] pl-0 box-border max-w-full gap-[20px] text-texticon-disabled-black mq750:flex-wrap mq450:pr-[73px] mq450:box-border">
        <Buttons
          download="/download.svg"
          button="About"
          arrowRight="/arrowright.svg"
          download1="/download.svg"
          button1="Contact"
          arrowRight1="/arrowright.svg"
          download2="/download.svg"
          button2="FAQ"
          arrowRight2="/arrowright.svg"
        />
        <Buttons
          download="/download.svg"
          button="privacy policy"
          arrowRight="/arrowright.svg"
          download1="/download.svg"
          button1="terms of service"
          arrowRight1="/arrowright.svg"
          download2="/download.svg"
          button2="gift cards"
          arrowRight2="/arrowright.svg"
        />
      </div>
    </section>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
