import PropTypes from "prop-types";
import { useState } from "react";
import axios from "axios";

const Subscription = ({ className = "" }) => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(null);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Replace with your Mailchimp API endpoint and API key
    const mailchimpEndpoint = "https://us14.api.mailchimp.com/3.0/lists/d5e392cb46/members";
    const mailchimpApiKey = "81e5115ebcd84aea9adcbd51947f33e0-us14";

    const backendEndpoint = "https://api.burpgroceries.com/subscribe";

    try {
      const response = await axios.post(backendEndpoint, { email });

      setStatus("success");
      console.log("Subscription successful:", response.data);
    } catch (error) {
      setStatus("error");
      console.error("Subscription error:", error);
    }
  };

  return (
    <section
      className={`self-stretch flex flex-col items-center justify-center pt-0 px-10 pb-3 box-border max-w-full text-left text-21xl text-texticon-primary-black font-button-200 mq1050:pl-5 mq1050:pr-5 mq1050:box-border ${className}`}
    >
      <div className="w-full max-w-[1200px] flex flex-row items-start justify-start gap-[48px] mq750:gap-[24px]">
        <div className="flex flex-col items-start justify-start gap-[48px] max-w-full mq750:gap-[24px]">
          <h1 className="m-0 w-full max-w-[428px] relative text-inherit tracking-[-1px] leading-[45px] font-bold font-inherit inline-block z-[1] mq750:w-full mq750:text-13xl mq750:leading-[36px] mq450:w-full mq450:text-5xl mq450:leading-[27px]">
            <p className="m-0">Hey!</p>
            <p className="m-0">Don’t miss out on our weekly updates.</p>
          </h1>
          <div className="self-stretch flex flex-col items-center justify-start gap-[14px] max-w-full text-sm text-texticon-secondary-black">
            <form onSubmit={handleFormSubmit} className="self-stretch flex flex-col items-start justify-start max-w-full">
              <div className="w-full max-w-[428px] flex flex-col items-start justify-start gap-[24px] mq750:w-full">
                <div className="self-stretch flex flex-row flex-wrap items-end justify-start [row-gap:20px]">
                  <div className="flex-1 flex flex-col items-start justify-start relative gap-[4px] min-w-[182px]">
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Enter Your Email Here"
                      className="self-stretch rounded-tl-mini rounded-tr-none rounded-br-none rounded-bl-mini bg-neutral-0 flex flex-row items-center justify-start py-[11px] px-[15px] gap-[8px] text-base text-texticon-disabled-white border-[1px] border-solid border-primary-500"
                      required
                    />
                  </div>
                  <button type="submit" className="cursor-pointer py-[0px] px-[18px] bg-primary-50 w-[148px] rounded-tl-none rounded-tr-mini rounded-br-mini rounded-bl-none box-border flex flex-row items-center justify-center gap-[16px] border-[1px] border-solid border-primary-500">
                    <b className="relative text-base tracking-[0.01em] leading-[20px] capitalize inline-block font-button-200 text-texticon-primary-black text-left">
                      Subscribe now
                    </b>
                  </button>
                </div>
                <div className="self-stretch relative text-base leading-[24px] text-texticon-primary-black">
                  <span>
                    By signing up for updates from Burp Groceries, you agree to
                    our &nbsp;
                  </span>
                  <a
                    className="text-[inherit]"
                    href="https://www.mill.com/privacy-policy"
                    target="_blank"
                  >
                    <span>
                      <span className="[text-decoration:underline]">
                        Privacy Policy
                      </span>
                    </span>
                  </a>
                  <span>.</span>
                </div>
                {status === "success" && <div className="text-green-500">Subscription successful!</div>}
                {status === "error" && <div className="text-red-500">Subscription failed. Please try again.</div>}
              </div>
            </form>
          </div>
        </div>
        <div className="w-[1000px] flex items-center justify-center">
          <img
            className="w-full h-auto rounded-t-81xl rounded-b-none object-cover"
            alt="Specialist"
            src="/photo1@2x.png"
          />
        </div>
      </div>
      <div className="w-[295px] shadow-[0px_8px_32px_rgba(0,_0,_0,_0.16)] rounded-xl bg-neutral-0 flex flex-col items-start justify-start p-6 box-border relative gap-[24px] z-[1] text-base text-texticon-primary-black mt-6 absolute left-[0px] bottom-[100px]">
        <div className="self-stretch flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-row items-center justify-between gap-[20px]">
            <b className="relative leading-[24px]">Product verified by</b>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 object-contain min-h-[24px]"
              loading="lazy"
              alt=""
              src="/more@2x.png"
            />
          </div>
          <div className="self-stretch relative text-sm leading-[20px] text-texticon-disabled-white">
            Selected specialists
          </div>
        </div>
        <div className="w-[227px] flex flex-col items-start justify-start gap-[24px] text-sm">
          <div className="flex flex-row items-center justify-start gap-[16px]">
            <img
              className="h-12 w-12 relative rounded-[50%] object-cover"
              loading="lazy"
              alt=""
              src="/photo@2x.png"
            />
            <div className="flex flex-col items-start justify-start gap-[4px]">
              <b className="relative leading-[20px] inline-block min-w-[126px]">
                RDN James Jones
              </b>
              <div className="flex flex-row items-center justify-start gap-[4px] text-texticon-disabled-white">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/contact.svg"
                />
                <div className="relative leading-[20px] inline-block min-w-[63px]">
                  Specialist
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-0 pr-[3px] pl-0 gap-[16px]">
            <img
              className="h-15 w-12 relative rounded-[50%] object-cover"
              loading="lazy"
              alt=""
              src="/photo-1@2x.png"
            />
            <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
              <b className="self-stretch relative leading-[20px]">
                RDN Lia Santana
              </b>
              <div className="flex flex-row items-center justify-start gap-[4px] text-texticon-disabled-white">
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/contact.svg"
                />
                <div className="relative leading-[20px] inline-block min-w-[63px]">
                  Specialist
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Subscription.propTypes = {
  className: PropTypes.string,
};

export default Subscription;