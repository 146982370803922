import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent1 from "../components/FrameComponent1";
import FrameComponent from "../components/FrameComponent";
import Subscription from "../components/Subscription";
import Footer from "../components/Footer";

const LandingPage = () => {
  return (
    <div className="w-full relative bg-neutral-0 overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[54.2px] box-border leading-[normal] tracking-[normal]">
      <FrameComponent2 />
      <FrameComponent1 />
      
      <FrameComponent />
      <Subscription />
      <section className="self-stretch flex flex-col items-start justify-center py-16 px-20 box-border relative gap-[10px] min-h-[749px] max-w-full text-center text-21xl text-texticon-primary-black font-button-200 mq750:pl-10 mq750:pr-10 mq750:box-border mq450:pt-[42px] mq450:pb-[42px] mq450:box-border">
        <img
          className="w-[1120px] h-[621px] absolute !m-[0] top-[64px] left-[60px] object-contain"
          loading="lazy"
          alt=""
          src="/mask-and-background@2x.png"
        />
        <div className="w-[444px] flex flex-col items-start justify-center gap-[40px] max-w-full z-[1] mq450:gap-[20px]">
          <h1 className="m-0 relative text-inherit tracking-[-1px] leading-[45px] font-bold font-inherit mq750:text-13xl mq750:leading-[36px] mq450:text-5xl mq450:leading-[27px]">
            Why now?
          </h1>
          <div className="self-stretch flex flex-col items-start justify-start gap-[24px] text-left text-base">
            <div className="self-stretch relative leading-[24px]">
              Amidst the rising number of chronic diseases in the USA, we are
              here to help you stay healthy by organizing your groceries and
              teaching you how to make healthier choices.
            </div>
            <div className="self-stretch relative leading-[24px]">
              Our service is designed to simplify grocery management while
              empowering individuals with knowledge to make healthier choices.
            </div>
            
          </div>
          
        </div>
        
      </section>
      
      <Footer />
    </div>
  );
};

export default LandingPage;
