import { useCallback } from "react";
import PropTypes from "prop-types";

const FrameComponent2 = ({ className = "" }) => {
  const onSmallButtonClick = useCallback(() => {
    // Please sync "Log in" to the project
  }, []);

  const onSmallButtonClick1 = useCallback(() => {
    // Please sync "Enter Lab Results" to the project
  }, []);

  return (
    <section
      className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-16 box-border max-w-full ${className}`}
    >
      <header className="flex-1 bg-neutral-0 flex flex-row items-center justify-between py-6 px-20 box-border top-[0] z-[99] sticky max-w-full gap-[20px] mq750:pl-10 mq750:pr-10 mq750:box-border">
        <img
          className="h-[60px] w-60 relative object-contain"
          loading="lazy"
          alt=""
          src="/transparent-logo@2x.png"
        />
        <div className="flex flex-row items-center justify-end gap-[16px]">
          <button
            className="cursor-pointer [border:none] py-4 px-[23px] bg-[transparent] rounded-xl flex flex-row items-center justify-center gap-[8px]"
            onClick={onSmallButtonClick}
          >
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
              alt=""
              src="/download.svg"
            />
            <a className="[text-decoration:none] relative text-base tracking-[0.01em] leading-[18px] capitalize font-bold font-button-200 text-primary-500 text-left inline-block min-w-[43px]">
              login
            </a>
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
              alt=""
              src="/arrowright.svg"
            />
          </button>
          <button
            className="cursor-pointer [border:none] py-4 px-[23px] bg-primary-500 rounded-mini flex flex-row items-center justify-center gap-[8px]"
            onClick={onSmallButtonClick1}
          >
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
              alt=""
              src="/download.svg"
            />
            <a className="[text-decoration:none] relative text-base tracking-[0.01em] leading-[18px] capitalize font-bold font-button-200 text-neutral-0 text-left inline-block min-w-[120px] whitespace-nowrap">
              Start Shopping
            </a>
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
              alt=""
              src="/arrowright.svg"
            />
          </button>
        </div>
      </header>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
