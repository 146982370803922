import PropTypes from "prop-types";

const Buttons = ({
  className = "",
  download,
  button,
  arrowRight,
  download1,
  button1,
  arrowRight1,
  download2,
  button2,
  arrowRight2,
}) => {
  return (
    <div
      className={`flex flex-col items-start justify-start gap-[24px] text-left text-base text-texticon-disabled-black font-button-200 ${className}`}
    >
      <div className="rounded-xl flex flex-row items-center justify-center gap-[8px]">
        <img
          className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
          alt=""
          src={download}
        />
        <b className="relative tracking-[0.01em] leading-[18px] capitalize inline-block min-w-[50px]">
          {button}
        </b>
        <img
          className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
          alt=""
          src={arrowRight}
        />
      </div>
      <div className="rounded-xl flex flex-row items-center justify-center gap-[8px]">
        <img
          className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
          alt=""
          src={download1}
        />
        <b className="relative tracking-[0.01em] leading-[18px] capitalize inline-block min-w-[65px]">
          {button1}
        </b>
        <img
          className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
          alt=""
          src={arrowRight1}
        />
      </div>
      <div className="rounded-xl flex flex-row items-center justify-center gap-[8px]">
        <img
          className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
          alt=""
          src={download2}
        />
        <b className="relative tracking-[0.01em] leading-[18px] capitalize inline-block min-w-[32px]">
          {button2}
        </b>
        <img
          className="h-4 w-4 relative overflow-hidden shrink-0 hidden"
          alt=""
          src={arrowRight2}
        />
      </div>
    </div>
  );
};

Buttons.propTypes = {
  className: PropTypes.string,
  download: PropTypes.string,
  button: PropTypes.string,
  arrowRight: PropTypes.string,
  download1: PropTypes.string,
  button1: PropTypes.string,
  arrowRight1: PropTypes.string,
  download2: PropTypes.string,
  button2: PropTypes.string,
  arrowRight2: PropTypes.string,
};

export default Buttons;
