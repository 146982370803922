import PropTypes from "prop-types";

const FrameComponent = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-32 box-border max-w-full text-center text-21xl text-texticon-primary-black font-button-200 mq750:pb-[83px] mq750:box-border ${className}`}
    >
      <div className="w-[831px] flex flex-col items-start justify-start gap-[48px] max-w-full mq450:gap-[24px]">
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-[67px] box-border max-w-full mq1050:pl-[33px] mq1050:pr-[33px] mq1050:box-border">
          <div className="h-[420px] flex-1 relative max-w-full">
            <div className="absolute top-[24.2px] left-[87.3px] [filter:blur(100px)] rounded-[50%] bg-secondary-500 w-[521px] h-[346px]" />
            <img
              className="absolute top-[0px] left-[0px] w-full h-full object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/photo-laptop@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
          <h1 className="m-0 self-stretch relative text-inherit tracking-[-1px] leading-[45px] font-bold font-inherit mq750:text-13xl mq750:leading-[36px] mq450:text-5xl mq450:leading-[27px]">
            Modern grocery shopping software that creates a unique experience
            for your health.
          </h1>
          <div className="self-stretch relative text-base leading-[24px]">
            <p className="m-0">{`With our simple process just input your health profile variables and that’s it! `}</p>
            <p className="m-0">Tailored-grocery shopping at your reach.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
