import { useCallback } from "react";
import PropTypes from "prop-types";

const FrameComponent1 = ({ className = "" }) => {
  const onInputContainerClick = useCallback(() => {
    // Please sync "Landing Page_Dropdown" to the project
  }, []);

  return (
    <section
      className={`w-full flex flex-row items-center justify-between px-20 py-16 box-border text-center text-texticon-primary-black font-button-200 ${className}`}
    >
      <div className="flex flex-col items-start gap-1" style={{ width: '543px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '16px' }}>
        <div className="flex flex-col items-start justify-start text-left gap-2">
          <h1 style={{
            color: '#292E32',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: '"DM Sans"',
            fontSize: '60px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '70px',
            letterSpacing: '-1px',
            margin: 0,
          }}>
            Groceries supplies
          </h1>
          <h1 style={{
            color: '#292E32',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: '"DM Sans"',
            fontSize: '60px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '70px',
            letterSpacing: '-1px',
            margin: 0,
          }}>
            tailored for you.
          </h1>
          <div className="w-[115px] h-1.5 bg-secondary-100"></div>
        </div>
        <div className="flex flex-col items-start justify-start text-left">
          <h3 style={{
            color: '#292E32',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: '"DM Sans"',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '30px',
            margin: 0,
          }}>
            We're building the first smart grocery shopping experience for every health profile. Together.
          </h3>
        </div>
        <div className="flex flex-col items-start justify-start text-left text-lg">
          <p>Start by searching any condition or symptom.</p>
          <div
            className="w-full max-w-[421px] bg-neutral-0 flex items-center justify-start pt-5 px-4 pb-4 gap-4 cursor-pointer border-b border-neutral-200"
            onClick={onInputContainerClick}
          >
            <img
              className="w-6 h-6"
              alt=""
              src="/search.svg"
            />
            <input
              className="w-full border-none outline-none text-lg bg-transparent leading-6 text-texticon-secondary-white text-left p-0"
              placeholder="Search conditions, treatments..."
              type="text"
            />
          </div>
        </div>
        <div className="flex items-center justify-start gap-4 text-primary-500 text-base">
          <div className="flex items-center justify-center gap-2 cursor-pointer">
            <img
              className="h-6 w-6"
              loading="lazy"
              alt=""
              src="/help.svg"
            />
            <b className="tracking-wider leading-5">How it works</b>
          </div>
          <div className="w-px h-6 bg-neutral-100"></div>
          <div className="flex items-center justify-center gap-2 cursor-pointer">
            <img
              className="h-6 w-6"
              loading="lazy"
              alt=""
              src="/pause.svg"
            />
            <b className="tracking-wider leading-5">Watch video</b>
          </div>
        </div>
      </div>
      <img
        style={{
          width: '652px',
          height: '556.826px',
          flexShrink: 0,
        }}
        loading="lazy"
        alt=""
        src="/photo-and-doodle@2x.png"
      />
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;